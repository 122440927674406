import { memo, useCallback } from "react"

import { ReactComponent as AddIcon } from "@spatialsys/assets/icons/material-filled/add.svg"
import { InteractionName, InteractionType, useTrackInteraction } from "@spatialsys/react/analytics"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import { useIsMobile } from "@spatialsys/web/core/js/hooks/use-is-mobile"
import { Button, cn } from "@spatialsys/web/ui"

type CtaButtonProps = {
  disableCreateSpaceModal?: boolean
}

export const CtaButton = memo(function CtaButton({ disableCreateSpaceModal }: CtaButtonProps) {
  const isMobile = useIsMobile()
  const { isAuthenticated, isAuthless, isAuthenticatingOrLoggingIn } = useAuthState()
  const trackInteraction = useTrackInteraction()

  const actions = useAppContext((context) => context.actions)
  const openCreateSpaceModal = useCallback(() => actions.openModal({ type: Modals.CreateSpace }), [actions])

  const onClick = useCallback(() => {
    if (isMobile && isAuthenticated && !isAuthless) {
      // Pushes to `/create`, so do not open any modals
      return
    }
    if (!isAuthenticated || isAuthless) {
      actions.openModal({ type: Modals.Login, payload: {} })
    } else {
      if (!disableCreateSpaceModal) {
        openCreateSpaceModal()
      }
    }
  }, [actions, disableCreateSpaceModal, isAuthenticated, isAuthless, isMobile, openCreateSpaceModal])

  return (
    <div className="flex flex-col gap-2 mobile:flex-row sm:gap-3 md:gap-4 lg:gap-4 xl:gap-4">
      {!isAuthenticated && (
        <Button
          as={isMobile && isAuthenticated ? "a" : "button"}
          href={isMobile && isAuthenticated ? "/create" : undefined}
          className={cn(
            "w-fit bg-foreground text-base text-background",
            // Except on small screens
            "sm:border sm:border-solid sm:bg-transparent sm:text-foreground sm:hover:scale-100 sm:hover:border-foreground sm:hover:bg-foreground sm:hover:text-background dark:sm:border-white/20",
            "sm:border-black/20 sm:text-foreground"
          )}
          size="md"
          isLoading={isAuthenticatingOrLoggingIn}
          noShadow
          onClick={() => {
            onClick()
            trackInteraction({ type: InteractionType.Click, name: InteractionName.NavbarLogin })
          }}
        >
          Log In
        </Button>
      )}

      <Button
        as={isMobile && isAuthenticated ? "a" : "button"}
        href={isMobile && isAuthenticated ? "/create" : undefined}
        className="relative w-fit overflow-hidden bg-gradient-to-r from-[#00CCFF] to-[#0033FF] pl-4 pr-6 text-base text-white shadow-none hover:scale-100 sm:!shadow-[0_4px_32px_4px_rgba(0,102,255,.6)]"
        size="md"
        isLoading={isAuthenticatingOrLoggingIn}
        leftIcon={<AddIcon className="icon icon-md pointer-events-none z-10" />}
        onClick={() => {
          onClick()
          trackInteraction({ type: InteractionType.Click, name: InteractionName.NavbarCta })
        }}
      >
        <span className="pointer-events-none z-10">
          {isAuthenticated && !isAuthless ? "New Space" : "Create for Free"}
        </span>
        <div className="absolute right-0 top-0 size-full bg-[#0044ff] opacity-0 transition-opacity duration-500 hover:opacity-100" />
      </Button>
    </div>
  )
})
